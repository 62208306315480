import React, { FC } from "react";

import { MonetizationOn } from "@mui/icons-material";
import { Chip, ChipProps } from "@mui/material";

import { IShiftIncentiveLevel } from "@/api";
import { NOT_EXISTING_UUID } from "@/common/constants";
import { useUnitShiftIncentiveLevels } from "@/common/hooks";
import { theme, white } from "@/common/theming";
import { emptySx } from "@/common/types";

export const ShiftIncentiveLevel: FC<
  ChipProps & {
    shiftIncentiveLevel?: IShiftIncentiveLevel;
    shiftIncentiveLevelId?: IShiftIncentiveLevel["id"];
    unitId?: string;
    showNone?: boolean;
    withDollarSign?: boolean;
    inline?: boolean;
  }
> = React.forwardRef(
  (
    {
      shiftIncentiveLevel,
      shiftIncentiveLevelId,
      unitId,
      sx,
      showNone,
      withDollarSign,
      inline,
      ...otherProps
    },
    ref,
  ) => {
    const storeShiftIncentiveLevel =
      useUnitShiftIncentiveLevels(unitId).byId[shiftIncentiveLevelId || NOT_EXISTING_UUID];
    shiftIncentiveLevel ||= storeShiftIncentiveLevel;
    if (!shiftIncentiveLevel && !showNone) return null;

    const backgroundColor = shiftIncentiveLevel?.color || white;
    const color = theme.palette.getContrastText(backgroundColor);
    const label = shiftIncentiveLevel?.label || "None";

    const isSmall = inline || otherProps.size === "small";

    return (
      <Chip
        ref={ref}
        label={label}
        icon={
          withDollarSign && shiftIncentiveLevel ? (
            <MonetizationOn
              sx={{
                color: color + "!important",
                ...(isSmall ? { fontSize: "14px !important" } : {}),
                ...(inline ? { mr: "-1px !important" } : {}),
              }}
              fontSize="small"
            />
          ) : undefined
        }
        sx={{
          ...(!isSmall ? { width: "120px" } : {}),
          ...(inline ? { height: "20px", mx: 0.5, span: { p: 0, px: 0.5 } } : {}),
          backgroundColor,
          color,
          borderRadius: "5px",
          "-webkit-text-fill-color": color,
          ...(sx || emptySx),
        }}
        {...otherProps}
      />
    );
  },
);
