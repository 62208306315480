import { Control } from "react-hook-form";

import { omit } from "lodash";

import { IShiftIncentiveLevel, IUnit } from "@/api";
import { useDeepMemo, useMap, useUnitShiftIncentiveLevels } from "@/common/hooks";

import CustomSelect, { CustomSelectControlled } from "../TrackedComponents/Select";
import { ICustomSelectControlledProps, ICustomSelectItem } from "../TrackedComponents/Select/types";

import { ShiftIncentiveLevel } from "./Tag";

export const IncentiveLevelDropdown = (
  props: Omit<
    ICustomSelectControlledProps<IShiftIncentiveLevel>,
    "control" | "multiple" | "items" | "label" | "trackingLabel"
  > & {
    unitId?: IUnit["id"];
    multiple?: false;
    items?: never;
    label?: string;
    trackingLabel?: string;
    control?: Control<
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      any & {
        incentiveLevelId?: IShiftIncentiveLevel["id"];
      }
    >;
  },
) => {
  const unitIncentiveLevels = useUnitShiftIncentiveLevels(props.unitId).array;

  const items = useMap(unitIncentiveLevels, (level) => ({
    label: level.label,
    value: level.id,
    item: level,
  }));

  const computedProps = useDeepMemo(() => {
    const otherProps = omit(props, [
      "MenuProps",
      "label",
      "trackingLabel",
      "noneOption",
      "className",
    ]);

    return {
      className: (props.className || "") + " shift-incentive-level-dropdown",
      label: props.label || "Incentive Level",
      trackingLabel: "trackingLabel" in props ? props.trackingLabel : "incentive-level-dropdown",
      MenuProps: { className: "shift-incentive-level-dropdown-menu", ...props.MenuProps } as const,
      variant: "outlined" as const,
      items: items,
      noneOption: true,
      displayEmpty: true,
      optionRenderComponent: (shiftIncentiveLevel?: ICustomSelectItem<IShiftIncentiveLevel>) => (
        <ShiftIncentiveLevel
          shiftIncentiveLevel={shiftIncentiveLevel?.item}
          sx={{ width: "100%" }}
        />
      ),
      renderMultiSelectedValue: (shiftIncentiveLevel?: ICustomSelectItem<IShiftIncentiveLevel>) => (
        <ShiftIncentiveLevel
          shiftIncentiveLevel={shiftIncentiveLevel?.item}
          sx={{ width: "100%" }}
        />
      ),
      renderNoneValue: () => (
        <ShiftIncentiveLevel shiftIncentiveLevel={undefined} sx={{ width: "100%" }} showNone />
      ),
      ...otherProps,
    };
  }, [props]);

  if (computedProps.control)
    // @ts-expect-error - label weird type issue
    return <CustomSelectControlled<IShiftIncentiveLevel> {...computedProps} />;

  return <CustomSelect<IShiftIncentiveLevel> {...computedProps} />;
};
