import { ISODateString, YyyyMmDd } from "@m7-health/shared-utils";

import { EStaffStatus } from "~/features/Roster/types";
import { IUser } from "~/features/User/types";
import { IUnitBasic } from "~/routes/api/types";

import { Uuid } from "@/common/types";

import { APIListResponse } from "../_shared/types";
import { Attribute } from "../attribute";
import { IRoster } from "../roster/types";
import { ISchedule } from "../schedule";
import { ShiftType } from "../shiftType";
import { IStaffCategory } from "../staffCategory";
import { IStaffType } from "../staffType";
import { IUnit } from "../unit";

export type IStaffDetails = StaffDetails.DTO;

export namespace StaffDetails {
  export enum EEmploymentType {
    fullTime = "Full time",
    partTime = "Part time",
    perDiem = "Per diem",
    travelStaff = "Travel staff",
    inHouseContract = "In-house contract",
    flex = "Flex",
    contract = "Contract",
  }

  export enum EPreferredWorkingHours {
    "day7A7P" = "day7A7P",
    "night7P7A" = "night7P7A",
  }

  export interface DTO {
    id: number;
    userId: IUser["id"];
    homeUnitId: IUnitBasic["id"];
    preferredWorkingHours: EPreferredWorkingHours[] | null;
    status: EStaffStatus;
    shiftType?: ShiftType.DTO["key"];
    staffTypeKey: IStaffType["key"];
    totalWeekendShiftsRequiredPerSchedule: number;
    totalShiftsRequiredPerSchedule: number;
    note?: string;
    postDateStatusUpdateType: "inactive" | "suspended" | null;
    postDateStatusUpdateDate: ISODateString | null;

    // Relationships
    attributes: Attribute.DTO[];
    attributeKeys?: Attribute.DTO["key"][];
    staffType: IStaffType & { staffCategory: IStaffCategory };
    user: IUser & { rosters: IRoster[] };
    employmentType: EEmploymentType;
    openShiftsToView?: ShiftType.Unit.DTO["id"];
  }

  export interface ShiftMetadataDTO {
    staffId: IUser["id"];
    lastFloatedAt: YyyyMmDd | null;
    lastCalledInAt: YyyyMmDd | null;
    lastCancelledAt: YyyyMmDd | null;
    lastOnCallAt: YyyyMmDd | null;
    lastFlexedAt: YyyyMmDd | null;
    lastRequestOnCallAt: YyyyMmDd | null;
    lastOnCall4hAt: YyyyMmDd | null;
  }

  export namespace API {
    const PATH_ROOT = "/staff-details";
    export namespace List {
      export const PATH = PATH_ROOT;
      export interface QueryParams {
        /** @warning use carefully, too much staffIds would create error with too lengthy query params */
        staffIds?: IUser["id"][];
        scheduleIds?: ISchedule["id"][];
        homeUnitIds?: IUnit["id"][];
        /* looks at the roster table and pulls all staff details that are on this unit at all */
        unitIds?: IUnit["id"][];
        workingOnDate?: YyyyMmDd;
        /* Value of true means include active, false means include inactive
         * Possible cases:
         *  - undefined or []: No filter applied, returns all staff.
         *  - [true]: Only active staff.
         *  - [false]: Only inactive staff.
         *  - [true, false]: Both active and inactive staff. **/
        active?: boolean[];
      }

      export type Response = APIListResponse<DTO>;
    }

    export namespace ListShiftMetadata {
      export const PATH = PATH_ROOT + "/shifts-metadata";
      export interface QueryParams {
        /** @warning use carefully, too much staffIds would create error with too lengthy query params */
        staffIds?: IUser["id"][];
      }

      export type Response = APIListResponse<ShiftMetadataDTO>;
    }

    export namespace BulkSave {
      export const PATH = PATH_ROOT + "/bulk-save";
      export interface QueryParams {
        staffDetails: TBulkSaveStaffDetails;
      }
      export type Response = APIListResponse<DTO>;
    }
  }
}

type TStaffDetailToUpdate = { userId: Uuid } & Partial<Pick<StaffDetails.DTO, "attributeKeys">>;

export type TBulkSaveStaffDetails = TStaffDetailToUpdate[];
