import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";

import { IStaffShift, StaffCategory, StaffShift } from "@/api";

import { ESchedulePageTabs, ModalsWithIsOpenOnly } from "../types";

import { initialState, ISchedulerGridState } from "./initialState";

const schedulerGridSlice = createSlice({
  name: "schedulerGrid",
  initialState,
  reducers: {
    resetSchedulerGridState: () => initialState,
    setScheduleTab: (state, action: PayloadAction<ESchedulePageTabs>) => {
      state.schedulePageTab = action.payload;
    },

    setGridFilter: <T extends keyof ISchedulerGridState["grid"]["filters"]>(
      state: Draft<ISchedulerGridState>,
      action: PayloadAction<{
        key: T;
        value: ISchedulerGridState["grid"]["filters"][T];
      }>,
    ) => {
      const { key, value } = action.payload;
      state.grid.filters[key] = value;
    },
    setGridFilters: (
      state: Draft<ISchedulerGridState>,
      action: PayloadAction<Partial<ISchedulerGridState["grid"]["filters"]>>,
    ) => {
      state.grid.filters = {
        ...state.grid.filters,
        ...action.payload,
      };
    },

    resetGridFilters: (state) => {
      state.grid.filters = initialState.grid.filters;
    },

    setGridStaffCategory: (state, action: PayloadAction<StaffCategory.EKey>) => {
      state.grid.selectedStaffCategory = action.payload;
    },

    setBalancingScheduleType: (state, action: PayloadAction<StaffShift.EScheduleType>) => {
      state.scheduleType = action.payload;
    },

    // Modals actions
    openModal: (state, action: PayloadAction<ModalsWithIsOpenOnly>) => {
      state.modals[action.payload] = { isOpen: true };
    },

    closeModal: (state, action: PayloadAction<keyof ISchedulerGridState["modals"]>) => {
      state.modals[action.payload] = { isOpen: false };
    },

    updateModal: <T extends keyof ISchedulerGridState["modals"]>(
      state: Draft<ISchedulerGridState>,
      action: PayloadAction<{ key: T; value: Partial<ISchedulerGridState["modals"][T]> }>,
    ) => {
      const { key, value } = action.payload;
      state.modals[key] = {
        ...state.modals[key],
        ...value,
      };
    },

    openModalWithParams: <T extends keyof ISchedulerGridState["modals"]>(
      state: Draft<ISchedulerGridState>,
      action: PayloadAction<{
        key: T;
        initialValues?: Omit<NonNullable<ISchedulerGridState["modals"][T]>, "isOpen">;
      }>,
    ) => {
      const modalKey = action.payload.key;
      const modalInitialValues = action.payload.initialValues || {};

      state.modals[modalKey] = {
        isOpen: true as boolean,
        ...modalInitialValues,
      } as ISchedulerGridState["modals"][T];
    },

    showShiftTypeDropdownInCell: (state, action: PayloadAction<boolean>) => {
      state.grid.showShiftTypeDropdownInCell = action?.payload;
    },

    setSidePanelDetailsData: (
      state,
      action: PayloadAction<Partial<ISchedulerGridState["grid"]["sidebar"]> | null>,
    ) => {
      if (action?.payload) {
        state.grid.sidebar = {
          ...state.grid.sidebar,
          ...action.payload,
        };
      } else {
        state.grid.sidebar = {
          ...initialState.grid.sidebar,
          // Always keep the isCollapsed state
          isCollapsed: state.grid.sidebar.isCollapsed,
        };
      }
    },

    toggleSideBar: (state) => {
      state.grid.sidebar.isCollapsed = !state.grid.sidebar.isCollapsed;
    },

    setSchedule(state, action: PayloadAction<ISchedulerGridState["schedule"]>) {
      state.schedule = action.payload;
    },

    updateGridData(state, action: PayloadAction<Partial<ISchedulerGridState["grid"]["data"]>>) {
      state.grid.data = {
        ...state.grid.data,
        ...action.payload,
      };
    },

    closeSideBar: (state) => {
      state.grid.sidebar = initialState.grid.sidebar;
    },

    // update staff shift by staff shift id
    // should work with multiple shifts per day
    // from means the current value of the shift
    // to means the new value of the shift
    updateStaffShift: (
      state,
      action: PayloadAction<
        // "delete"
        | { from: IStaffShift; to: null }
        // update
        | { from: IStaffShift; to: Partial<IStaffShift> }
        // create
        | { from: null; to: IStaffShift }
      >,
    ) => {
      state.grid.data.updates.redoHistory = [];

      const updatesHistory = state.grid.data.updates.undoHistory;
      const newValue =
        action.payload.to === null
          ? null
          : action.payload.from === null
            ? action.payload.to
            : { ...action.payload.from, ...action.payload.to };

      updatesHistory.push({
        from: action.payload.from,
        to: newValue,
      });

      const staffShiftId = (action.payload.from?.id || action.payload.to?.id)!;

      state.grid.data.updates.byStaffShiftId[staffShiftId] = newValue;
    },

    updateStaffShifts: (
      state,
      action: PayloadAction<
        (
          | { from: IStaffShift; to: null } // "delete"
          | { from: IStaffShift; to: Partial<IStaffShift> } // update
          | { from: null; to: IStaffShift } // create
        )[]
      >,
    ) => {
      state.grid.data.updates.redoHistory = [];

      const updatesHistory = state.grid.data.updates.undoHistory;
      action.payload.forEach((update) => {
        const newValue =
          update.to === null
            ? null
            : update.from === null
              ? update.to
              : { ...update.from, ...update.to };

        updatesHistory.push({
          from: update.from,
          to: newValue,
        });

        const staffShiftId = (update.from?.id || update.to?.id)!;

        state.grid.data.updates.byStaffShiftId[staffShiftId] = newValue;
      });
    },

    undoLastMove: (state) => {
      const updatesHistory = state.grid.data.updates.undoHistory;
      const lastUpdate = updatesHistory.pop();
      if (!lastUpdate) return;

      state.grid.data.updates.redoHistory.push(lastUpdate);

      const { from, to } = lastUpdate;
      if (from === null && to?.id) {
        delete state.grid.data.updates.byStaffShiftId[to.id];
        return;
      }

      if (to === null && from?.id) {
        delete state.grid.data.updates.byStaffShiftId[from.id];
        return;
      }

      const staffShiftId = (lastUpdate.from?.id || lastUpdate.to?.id)!;
      state.grid.data.updates.byStaffShiftId[staffShiftId] = lastUpdate.from;
    },

    redoLastMove: (state) => {
      const redoHistory = state.grid.data.updates.redoHistory;
      const lastUpdate = redoHistory.pop();
      if (!lastUpdate) return;

      state.grid.data.updates.undoHistory.push(lastUpdate);

      const { from, to } = lastUpdate;
      if (from === null && to?.id) {
        state.grid.data.updates.byStaffShiftId[to.id] = to;
        return;
      }

      if (to === null && from?.id) {
        state.grid.data.updates.byStaffShiftId[from.id] = null;
        return;
      }

      const staffShiftId = (lastUpdate.from?.id || lastUpdate.to?.id)!;
      state.grid.data.updates.byStaffShiftId[staffShiftId] = lastUpdate.to;
    },

    cancelChanges: (state) => {
      state.grid.data.updates.undoHistory = [];
      state.grid.data.updates.redoHistory = [];
      state.grid.data.updates.byStaffShiftId = {};
    },

    initiateSave: (state) => {
      state.modals.saveChanges = { isOpen: true };
    },

    cleanOnNextRefetch: (state) => {
      state.grid.data.updates.cleanOnNextRefetch = true;
    },

    doEraseHistory: (state) => {
      state.grid.data.updates = initialState.grid.data.updates;
    },

    setDataIsLoading: (state, action: PayloadAction<boolean>) => {
      state.grid.data.isLoading = action.payload;
    },
  },
});

export const SchedulerGridStore = {
  ...schedulerGridSlice.actions,
  initialState,
};
export default schedulerGridSlice.reducer;
