import { useCallback, useMemo } from "react";

import { m7DayJs } from "@m7-health/shared-utils";
import { keyBy, keys, map } from "lodash";

import {
  IStaffDetails,
  ISubmission,
  IUnit,
  Submission,
  useListStaffDetailsQuery,
  useListSubmissions,
} from "~/api";
import { useAppConfigQuery } from "~/features/User/queries";

import { NOT_EXISTING_UUID } from "@/common/constants";

import { useCurrentSelectedUnitId } from "./useCurrentUnitId";

export const useDownloadSubmissions = (
  providedUnitId?: IUnit["id"] | undefined,
  skipSubmissions?: boolean,
) => {
  // ** Queries -- data
  // Units
  const { data: { units = [] } = {} } = useAppConfigQuery();
  const unitsById = useMemo(() => keyBy(units, "id"), [units]);
  const currentUnitId = useCurrentSelectedUnitId();
  const unitId = providedUnitId || currentUnitId || NOT_EXISTING_UUID;
  const unit = unitsById[unitId];

  // submissions
  const { data: submissions = [] } = useListSubmissions(
    {
      unitIds: [unitId || NOT_EXISTING_UUID],
      statuses: [Submission.ESubmissionStatus.completed, Submission.ESubmissionStatus.ignored],
    },
    { skip: !unitId || skipSubmissions },
  );

  // Staff details
  // Include all staff for submissions
  const staffDetails = useListStaffDetailsQuery(
    { homeUnitIds: [unitId || NOT_EXISTING_UUID], active: [true, false] },
    { skip: !unitId },
  );
  const staffDetailsById = useMemo(() => keyBy(staffDetails.data, "userId"), [staffDetails.data]);

  return useCallback(() => {
    // File name: survey-responses__{unitName}__{date}.csv
    const fileName = `survey-responses__${unit?.name || ""}__${m7DayJs().format("YYYY-MM-DD")}.csv`;

    // ** HEADERS
    // User headers
    const userHeaders: {
      [key: string]: (userDetails: IStaffDetails) => string;
    } = {
      "user:id": ({ userId }) => userId,
      "user:first name": ({ user: { firstName } }) => firstName,
      "user:last name": ({ user: { lastName } }) => lastName,
      "user:email": ({ user: { email } }) => email,
      "user:employment type": ({ employmentType }) => employmentType,
      "user:preferred working hours": ({ preferredWorkingHours }) =>
        preferredWorkingHours?.join(", ") || "",
      "user:attributes": ({ attributes }) =>
        attributes.map((attribute) => attribute.name).join(", "),
      "user:home unit": ({ homeUnitId }) => unitsById[homeUnitId]?.name || "",
      "user:units": ({ user: { rosters } }) =>
        rosters.map((roster) => unitsById[roster.unitId]?.name).join(", "),
      "user:status": ({ status }) => status,
    };
    // submission headers
    const submissionHeaders: {
      [key: string]: (submission: ISubmission) => string;
    } = {
      "submission:id": ({ id }) => id,
      "submission:status": ({ status }) => status,
      "submission:created at": ({ createdAt }) => createdAt,
      "submission:status set at": ({ statusSetAt }) => statusSetAt,
      "submission:raw content": ({ content }) => JSON.stringify(content),
    };
    // Question headers
    const questionHeaders = submissions.reduce((acc, submission) => {
      keys(submission.content).forEach((question) => {
        acc.add(question.toString());
      });
      return acc;
    }, new Set<string>());

    // Returns an array of headers, and an array of rows
    const formattedData = {
      // Specific order that must match the order of the data
      headers: [
        ...keys(userHeaders),
        ...keys(submissionHeaders),
        ...Array.from(questionHeaders).map((question) => `question:${question}`),
      ],
      data: submissions?.map((submission) => {
        const currentStaffDetails = staffDetailsById[submission.userId];
        if (!currentStaffDetails) return [];

        // Specific order that must match the order of the headers
        return [
          ...map(userHeaders, (headerFn) => headerFn(currentStaffDetails)),
          ...map(submissionHeaders, (headerFn) => headerFn(submission)),
          ...Array.from(questionHeaders).map((question) =>
            submission.content[question]?.toString(),
          ),
        ];
      }),
    };

    // Create blob
    const content = arrayToCsv([formattedData.headers, ...formattedData.data]);
    const blob = new Blob([content], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    // Create a link to download it
    const elementToClick = document.createElement("a");
    elementToClick.href = url;
    elementToClick.setAttribute("download", fileName);
    elementToClick.click();
  }, [staffDetailsById, submissions, unitsById, unit]);
};

function arrayToCsv(data: (string | number | undefined)[][]) {
  return data
    .map(
      (row) =>
        row
          .map(String) // convert every value to String
          .map((v) => v.replaceAll('"', '""')) // escape double quotes
          .map((v) => `"${v}"`) // quote it
          .join(","), // comma-separated
    )
    .join("\r\n"); // rows starting on new lines
}
