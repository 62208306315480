// eslint-disable-next-line deprecate/import
import { useFlags } from "launchdarkly-react-client-sdk";

import { PlatformUpdateInProgressFeatureFlag } from "../components/AppWideModal/types";

export type TAvailableFeatureFlags = {
  // Permanent flags
  platformUpdatesInProgress: PlatformUpdateInProgressFeatureFlag;
  disableTypeToConfirmModals: boolean;

  // Feature related flags
  questionnairesV1: boolean;
  dropShiftRequest: boolean;
  useStaffNoteInSchedulerGrid: boolean;
  newStaffingTargetsModal: boolean;
  updateStaffingLevelInUnitCard: boolean;
  schedulerGridShowFutureDays: boolean;
  showHouseViewToKioskUser: boolean;
  scheduleDatesTzFix: boolean;
  showEventLogs: boolean;
  hvShowUnitPicker: boolean;
  multipleUserRoles: boolean;
  hvPositionAsTab: boolean;
  preferenceRequirementsV2: boolean;
  holidaySchedule: boolean;
  staffingTargetLevelModalQueuesToInputPatientCount: boolean;
  incentivizedShifts: boolean;
  schedulerGridConfigFab: boolean;

  // To deprecate and change to configs
  enableUsersToChangeTheirEmail: boolean;
  timeBasedTargetLevels: boolean;
};

export const useAppFlags = useFlags<TAvailableFeatureFlags>;
